export const Services = ({ data, type }) => {
  return (
    <div id="services" className={`text-center ${type}`}>
      <div className="container">
        <div className="section-title">
          <h2>{data?.title}</h2>
          <p>{data?.description}</p>
        </div>
        <div className="row">
          {data?.row1?.map((item, i) => (
            <div key={`${item.name}-${i}`} className={`col-md-${data.rowMd}`}>
              <div className="identifier">
                {item.idType === "text" ? (
                  <span>{item.id}</span>
                ) : (
                  <img src={item.id} alt="ikone sherbimi" />
                )}
              </div>
              <div className="service-desc">
                <h3>{item.name}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        {data?.row2 && (
          <div className="row">
            {data?.row2?.map((item, i) => (
              <div key={`${item.name}-${i}`} className={`col-md-${data.rowMd}`}>
                <div className="identifier">
                  {item.idType === "text" ? (
                    <span>{item.id}</span>
                  ) : (
                    <img src={item.id} alt="ikone sherbimi" />
                  )}
                </div>
                <div className="service-desc">
                  <h3>{item.name}</h3>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {data?.row3 && (
          <div className="row">
            {data?.row3?.map((item, i) => (
              <div key={`${item.name}-${i}`} className={`col-md-${data.rowMd}`}>
                <div className="identifier">
                  {item.idType === "text" ? (
                    <span>{item.id}</span>
                  ) : (
                    <img src={item.id} alt="ikone sherbimi" />
                  )}
                </div>
                <div className="service-desc">
                  <h3>{item.name}</h3>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
