export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-12 col-md-6">
                  <div className="item-wrapper">
                    <div className="icon-wrapper">
                      <img src={d.icon} alt="icon" />
                    </div>
                    <h3>{d.title}</h3>
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
