import { useState } from "react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          {/* <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div> */}
          <div className="col-md-12 col-md-offset-1 contact-info">
            <div className="section-title">
              <h2>Na kontaktoni</h2>
              <p>
                Ju lutemi na kontaktoni në numrin ose emailin e mëposhtëm dhe ne
                do t'ju kthejmë përgjigje sa më shpejt të jetë e mundur.
              </p>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-12 contact-item">
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Adresa
                  </span>
                  <span className="contact-data">
                    {props.data ? props.data.address : "loading"}
                  </span>
                </p>
              </div>
              <div className="col-md-4 col-sm-12 contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  <a href={`tel: ${props.data?.phone}`}>
                    <span className="contact-data">{props.data?.phone}</span>
                  </a>
                </p>
              </div>
              <div className="col-md-4 col-sm-12 contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  <a href={`mailto: ${props.data?.email}`}>
                    <span className="contact-data">{props.data?.email}</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  {props.data?.socials.map((social) => (
                    <li key={social.icon}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={social.url}
                      >
                        <i className={social.icon}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2022 - {new Date().getFullYear()} KRE Ndërtim</p>
          <p>
            Powered by
            <a href="https://techforgers.com"> TechForgers</a>
          </p>
        </div>
      </div>
    </div>
  );
};
