export const About = ({ data }) => {
  const { paragraph1, paragraph2, paragraph3 } = data || {};
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Kush jemi ne?</h2>
              <p>{paragraph1}</p>
              <p>{paragraph2}</p>
              <p>{paragraph3}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
